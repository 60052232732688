const ɵ0 = {
    SAMPLE: {
        HELLO: 'Merhaba Dünya!'
    }
};
export const locale = {
    lang: 'tr',
    data: ɵ0
};
export { ɵ0 };
