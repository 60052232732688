import * as i0 from "@angular/core";
export class CacheControlInterceptor {
    constructor() { }
    intercept(request, next) {
        // no-cache related headers:
        // 1. Cache-Control         For HTTP 1.1. servers
        // 2. Pragma                Compatible with legacy HTTP 1.0 servers that do not support Cache-Control header
        // 3. Expires               Sets the expiry date as the unix epoch
        // 4. If-Modified-Since     Explicitly state that the request must comply with the given range
        const noCacheRequest = request.clone({
            headers: request.headers.set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT')
                .set('If-Modified-Since', '0')
        });
        return next.handle(noCacheRequest);
    }
}
CacheControlInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CacheControlInterceptor_Factory() { return new CacheControlInterceptor(); }, token: CacheControlInterceptor, providedIn: "root" });
