const ɵ0 = {
    SAMPLE: {
        HELLO: 'Hello, World!',
        MESSAGE: 'Coming Soon'
    }
};
export const locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
