import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/core";
export class FuseMatchMediaService {
    /**
     * Constructor
     *
     * @param {MediaObserver} _mediaObserver
     */
    constructor(_mediaObserver) {
        this._mediaObserver = _mediaObserver;
        this.onMediaChange = new BehaviorSubject('');
        // Set the defaults
        this.activeMediaQuery = '';
        // Initialize
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        this._mediaObserver.media$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((change) => {
            if (this.activeMediaQuery !== change.mqAlias) {
                this.activeMediaQuery = change.mqAlias;
                this.onMediaChange.next(change.mqAlias);
            }
        });
    }
}
FuseMatchMediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseMatchMediaService_Factory() { return new FuseMatchMediaService(i0.ɵɵinject(i1.MediaObserver)); }, token: FuseMatchMediaService, providedIn: "root" });
