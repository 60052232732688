import { BehaviorSubject } from 'rxjs';
import { Md5 } from 'ts-md5';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./web-api.service";
import * as i2 from "./authentication.service";
import * as i3 from "./efiling.service";
import * as i4 from "@angular/material/snack-bar";
export class IdentityService {
    constructor(api, authentication, efiling, snack) {
        this.api = api;
        this.authentication = authentication;
        this.efiling = efiling;
        this.snack = snack;
        this.roles = new BehaviorSubject(null);
        this.firm = new BehaviorSubject(null);
        this.companyUsers = new BehaviorSubject(null);
        this.currentUser = new BehaviorSubject(null);
        this.allRoles = new BehaviorSubject(null);
        this.appCode = new BehaviorSubject('EFILING');
    }
    getUserRoles() {
        return this.api.post('/identity/getUserRoles', {})
            .pipe(tap(response => this.roles.next(response.data)));
    }
    getFirm() {
        return this.api.post('/identity/getFirm')
            .pipe(tap(response => this.firm.next(response.data)));
    }
    getAllCompanyUsers() {
        return this.api.get('/identity/getAllCompanyUsers')
            .pipe(tap(response => this.companyUsers.next(response.data)));
    }
    putSingleUser(user) {
        this.companyUsers.next(null);
        return this.api.post('/identity/editSingleUser', user);
    }
    mapToLogin(user) {
        const login = Object.assign({}, user);
        return login;
    }
    mapToUser(user) {
        const target = Object.assign({}, user);
        return target;
    }
    addFirmUser(user) {
        this.companyUsers.next(null);
        if (!user.password) {
            user.password = Math.random().toString(36).slice(-8);
        }
        return this.api.post('/identity/addFirmUser', user);
    }
    getApplicationRoles() {
        return this.api.post('/identity/ApplicationRoles')
            .pipe(tap(response => this.allRoles.next(response.data)));
    }
    startUserResetPassword(email, silent) {
        const token = Md5.hashStr(email).toString();
        this.efiling.forgotPassword(email, token).subscribe((result) => {
            if (!silent) {
                const message = result.isSuccess
                    ? `Welcome instructions have been successfully sent to ${email}.`
                    : 'There was a problem resetting the password for this user.';
                this.snack.open(message, null, { duration: 2000 });
            }
        });
    }
    refreshCompanyUsers() {
        this.getAllCompanyUsers().subscribe(response => this.companyUsers.next(response.data));
    }
}
IdentityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdentityService_Factory() { return new IdentityService(i0.ɵɵinject(i1.WebApiService), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.EfilingService), i0.ɵɵinject(i4.MatSnackBar)); }, token: IdentityService, providedIn: "root" });
